<template>
  <div class="btn-group">
    <!-- <button
      v-if="editMode"
      type="button"
      class="btn btn-secondary"
      @click="handleCancelConfiguration"
    >
    <span class="material-icons">
        clear
      </span>
    </button> -->
    <button
      type="button"
      class="btn btn-primary"
      :disabled="isDisabled"
      @click="handleSaveConfiguration"
      data-test-context-save
    >
      Save
    </button>
  </div>
</template>
<script>
export default {
  name: "SaveConfiguration",
  components: {},
  props: {
    mode: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      isClickable: true
    };
  },
  computed: {
    editMode() {
      return this.mode
    },
    isDisabled() {
      return this.disabled;
    }
  },
  methods: {
    handleSaveConfiguration() {
      this.$emit("saveConfiguration");
    },
    handleCancelConfiguration() {
      this.$emit("cancelConfiguration");
    }
  }
};
</script>
<style lang="scss">
button[disabled] {
  cursor: not-allowed;
}

@media (max-width: 320px) {
  .page__header .btn {
    width: 100vw;
  }
}
</style>
