<template>
  <div class="app-select">
    <label for="selectAcceptablesVersion" class="form-text my-auto">acceptables ver.</label>
    <select
      v-model="selectedOptions"
      id="selectAcceptablesVersion"
      class="form-select form-select-lg"
      aria-label="selectAcceptablesVersion"
      @change="handleSelectionChange($event.target)"
      data-test-select-acceptables-version
    >
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.value"
        :class="{ 'is-selected': isSelected }"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "AppSelect",
  props: {
    options: Array
  },
  data() {
    return {
      isSelected: false,
    };
  },
  computed: {
    selectedOptions: {
      get() {
        return this.$store.state.selectAcceptableId;
      },
      set(optionValue) {}
    }
  },
  methods: {
    handleSelectionChange(options) {
      for (let id in options) {
        const option = options[id];

        if (option?.selected) {
          this.$emit("selectionChanged", option.value);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  // background-size: 12px 8px;
  // border: 0;
  // border-radius: 0;
  // box-shadow: inset 1px 0 0 var(--color-grey-light);
  // color: var(--color-grey-medium);
  font-size: 0.9rem;
  // font-weight: 600;
  // padding-left: 1.2rem;

  &:focus {
    background-color: #eee;
    box-shadow: none;
  }
}
</style>
