<template>
  <div class="context-editor mb-5 mb-md-0 ps-md-0">
    <div class="d-flex align-items-end justify-content-between">
      <!-- <small class="form-text my-auto mb-2">context</small> -->
      <div
        class="
          code-actions
          d-flex
          flex-grow-1
          algn-items-center
          justify-content-between
        "
      ></div>
    </div>
    <div class="form-floating mb-3">
      <input
        v-model="name"
        id="contextId"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': !isNameValid }"
        :placeholder="name"
        @input="handleContextNameChange()"
        autocomplete="off"
        data-test-context-input-name
      />
      <label for="contextId" class="form-label">context name</label>
      <div v-if="!isNameValid" class="invalid-tooltip">Name is required</div>
    </div>

    <div class="form-floating mb-3">
      <input
        v-model="actual"
        id="contextActual"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': !isActualValid }"
        :placeholder="actual"
        minlength="1"
        @input="handleContextActualChange()"
        autocomplete="off"
        data-test-context-input-actual
      />
      <label for="contextActual" class="form-label">actual</label>
      <div v-if="!isActualValid" class="invalid-tooltip">
        Actual is required
      </div>
    </div>
    <div class="form-check">
      <div
        class="
          form-action-footer
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <input
          v-model="is_default"
          class="form-check-input"
          type="checkbox"
          id="contextDefault"
          name="keywordContext"
          :checked="is_default"
          @change="handleContextDefaultChange()"
          data-test-context-input-default
        />
        <label
          for="contextDefault"
          class="form-check-label ms-2 mt-1 me-auto fs-8 text-body"
          ><strong>Default</strong></label
        >
        <div>
          <button
            type="button"
            class="btn btn-text link-danger py-0 px-1 text-uppercase"
            @click="handleDeleteContext()"
            :disabled="name == ''"
            data-test-context-delete
          >
            delete
          </button>
          <!-- <button type="button" class="btn link-secondary py-0 px-1" 
          @click="handleCreateNewContext()"
          data-test-context-create
          >
            <span class="material-icons">
              post_add
            </span>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "ContextEditor",
  components: {},
  props: {},
  mounted() {
    const keywordContext = this.$store.getters.getSelectedContext;
    this.name = keywordContext.name;
    this.actual = keywordContext.actual;
    this.is_default = keywordContext.is_default;
    this.id = keywordContext.id;
    this.keyword_id = keywordContext.keyword_id;
  },
  data() {
    return {
      name: "",
      actual: "",
      is_default: false,
      id: null,
      keyword_id: null
    };
  },
  computed: {
    contextEditMode() {
      return this.$store.state.contextEditMode;
    },
    keywordContexts() {
      const defaultContext = this.$store.getters.getKeywordContexts.find(
        context => context.is_default
      );
      console.log("defaultContext", defaultContext);
      if (defaultContext) {
        return defaultContext;
      } else {
        return this.$store.getters.getKeywordContexts[0];
      }
    },
    isNameValid() {
      return this.name.length > 0;
    },
    isActualValid() {
      return this.actual.length > 0;
    },
    isNewContext() {
      return this.id == null;
    }
  },
  methods: {
    createUuid() {
      return uuidv4();
    },
    handleCancelContextEdit() {
      this.$emit("exitContextEditMode");
      const {
        id,
        name,
        actual,
        is_default
      } = this.$store.getters.getKeywordContext;
      this.id = id;
      this.name = name;
      this.actual = actual.join(", ");
      this.is_default = is_default;
    },
    handleContextNameChange() {
      const updatedContext = {
        name: this.name,
        id: this.id
      };
      this.$emit("contextNameChanged", updatedContext);
    },
    handleContextActualChange() {
      const updatedContext = {
        id: this.id,
        actual: this.actual
      };
      this.$emit("contextActualChanged", updatedContext);
    },
    handleContextDefaultChange() {
      const updatedContext = {
        id: this.id,
        is_default: this.is_default
      };
      this.$emit("contextDefaultChanged", updatedContext);
    },
    handleDeleteContext() {
      console.log("contextDeleted");
      this.$emit("contextDeleted", this.id);
    },
    handleSaveContext() {
      if (!this.isActualValid && !this.isNameValid) {
        return;
      }

      const context = {
        name: this.name,
        actual: this.actual.split(",").map(actual => actual.trim()),
        is_default: this.is_default,
        id: this.id ? this.id : this.createUuid(),
        keyword_id: this.keyword_id
      };
      const action = this.isNewContext ? "CREATE" : "UPDATE";
      this.$emit("contextSaved", { context, action });
    },
    handleCreateNewContext() {
      this.$emit("contextEditModeActive");
      this.name = "";
      this.actual = "";
      this.is_default = false;
      this.id = null;
    },
    handleToggleContextEditMode() {
      this.$emit("contextEditModeActive");
    }
  },
  watch: {
    "$store.state.selectedContextId": {
      deep: true,
      handler: function() {
        const {
          name,
          actual,
          is_default,
          id
        } = this.$store.getters?.getSelectedContext;
        this.name = name;
        this.actual = actual;
        this.is_default = is_default;
        this.id = id;
      }
    },
    
  }
};
</script>
<style lang="scss">
.form-action-footer {
  height: 31px;
}

.context-editor {
  
  @media screen and (min-width: 992px) {
    width: clamp(18rem, 24vmin, 30rem);
    // flex-basis: 24rem;
    // max-width: 30rem;
  }
}

.btn-link.bs-primary {
  color: var(--color-brand-green);
}
</style>
