<template>
  <keyword-configuration 
    @createNewContext="handleCreateNewContext" 
    @contextSelected="contextSelected" 
    @acceptablesChanged="acceptablesChanged" 
    @acceptablesUpdated="acceptablesUpdated" />
</template>

<script>
import KeywordConfiguration from "./views/KeywordConfiguration.vue";

export default {
  name: "AppView",
  components: { KeywordConfiguration },
  computed: {},
  methods: {
    handleCreateNewContext() {
      this.$emit("createNewContext");
    },
    contextSelected(contextId) {
      this.$emit("contextSelected", contextId);
    },
    acceptablesChanged(acceptablesId) {
      this.$emit("acceptablesChanged", acceptablesId)
    },
    acceptablesUpdated(acceptables) {
      this.$emit("acceptablesUpdated", acceptables);
    }
  }
};
</script>

<style lang="scss"></style>
