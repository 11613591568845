<template>
  <div class="json-editor d-flex flex-column flex-grow-1 position-relative">
    <app-select
      :options="contextAcceptables"
      class="select mb-4"
      @selectionChanged="acceptablesChanged"
    />
    <div id="jsoneditor" class="editor flex-grow-1" @acceptablesChanged="acceptablesChanged"></div>
    <div class="hidden" data-test-acceptable>{{ acceptable }}</div>
  </div>
</template>
<script>
import AppSelect from "../molecules/AppSelect.vue"
import JsonScript from "jsoneditor/dist/jsoneditor-minimalist";
import JsonStyles from "jsoneditor/dist/jsoneditor.min.css";
import {cloneDeep} from "lodash";
import {format} from "date-fns";
import {sub} from "date-fns";

export default {
  name: "JsonEditor",
  components: {
    AppSelect
  },
  props: {
  },
  data() {
    return {
      editor: null,
      acceptable: null,
    };
  },
  mounted() {
    JsonStyles;
    this.createEditor();
  },
  computed: {
    contextAcceptables() {
      return this.$store.getters.getSelectedContext.acceptables.map(acceptables => {
        return {
          value: acceptables.id,
          label: acceptables.created_at,
          id: acceptables.id
        }
      })
    }
  },
  methods: {
    dateString(date) {
      return date.toDateString();
    },
    createEditor() {
      const JSONEditor = JsonScript;
      const container = document.getElementById("jsoneditor");
      const options = {
        mode: "tree",
        modes: ["tree", "form", "code", "preview"],
        // search: false,
        colorPicker: false,
        onChange: () => {
          try {
            const acceptables = this.editor.get();
            this.$emit("acceptablesUpdated", acceptables);
            this.acceptable = acceptables;
          } catch (error) {
            console.log("acceptables not valid...yet");
          }
        }
      };
      this.editor = new JSONEditor(container, options);
      this.resetEditor();
    },
    resetEditor() {
      this.editor.set({
        acceptables: this.$store.getters.getSelectedAcceptables.acceptable
      });
      this.acceptable = this.$store.getters.getSelectedAcceptables.acceptable;
      this.editor.expandAll();
    },
    acceptablesChanged(acceptablesId) {
      this.$emit("acceptablesChanged", acceptablesId);
    },
  },
  watch: {
    "$store.state.selectAcceptableId": {
      deep: true,
      handler() {
        console.log("another acceptable selected")
        this.resetEditor();
      }
    },
    "$store.state.keywordContext": {
      deep: true,
      handler() {
        if(this.$store.state.keywordContext?.state == "new") {
          this.editor.set({
            acceptables: [[[""]]]
          });
          this.editor.expandAll();
        }
      }
    }
  }
};
</script>
<style lang="scss">

.json-editor {

  @media screen and (min-width: 992px) {
    .app-select {
      position: absolute;
      top: 5px;
      right: 200px;
      min-width: 150px;
      max-width: 250px;
      z-index: 1;
    }

    select {      
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
    }

    label {
      display: none;
    }

    .form-select {
      background-size: 12px 8px;
      border: 0;
      border-radius: 0;
      box-shadow: inset 1px 0 0 var(--color-grey-light);
      color: var(--color-grey-medium);
    }
  }
}

  .editor {
    // width: 630px;
  }

.hidden {
  display: none;
}
</style>
