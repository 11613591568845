<template>
  <div id="app" class="app__container d-flex">
    <div class="content-wrap">
      <app-alert
        :alertLevel="$store.state.alert.level"
        :alertTitle="$store.state.alert.title"
        :alertMessage="$store.state.alert.message"
        :show="$store.state.alert.active"
        @closeAlert="closeAlert"
      />
      <app-header
        @editModeActive="editModeActive"
        @cancelConfiguration="cancelConfiguration" 
        @saveConfiguration="saveConfiguration"
        />
      <main id="main" class="main-content bg-light">
        <loading-icon v-if="!dataLoaded" />
        <router-view 
          v-else
          @createNewContext="handleCreateNewContext" 
          @contextSelected="contextSelected"
          @acceptablesChanged="acceptablesChanged" 
          @acceptablesUpdated="acceptablesUpdated"
        />
      </main>
      <app-footer />
    </div>
  </div>
</template>

<script>
import "./assets/scss/styles.scss";

import LoadingIcon from "./components/atoms/LoadingIcon.vue";
import AppAlert from "./components/molecules/AppAlert.vue";
import AppFooter from "./components/molecules/AppFooter.vue";
import AppHeader from "./components/molecules/AppHeader.vue";

export default {
  name: "keywordConfigurationAssistanceTool",
  components: {
    AppAlert,
    AppHeader,
    AppFooter,
    LoadingIcon
  },
  data() {
    return {};
  },
  mounted() {
    const keywordId = this.$route.query.keywordId;
    this.$store.dispatch("initializeApp", keywordId);
    // const contextExists = this.$store.getters.getKeywordContexts.find(context => context.id != undefined)
    // console.log("++++++++", contextExists)

    // if (contextExists) {
    //   this.$store.dispatch("initializeApp", keywordId)
    // } else {
    //   const alert = {
    //     message: "Context Does Not Exist",
    //     title: "Oh No!",
    //     level: "danger",
    //     timeToDisplay: 5000,
    //     active: false
    //   }
    //   this.$store.dispatch("setMessage", alert)
    // }
  },
  computed: {
    dataLoaded() {
      return this.$store.state.dataIsLoaded;
    }
  },
  methods: {
    closeAlert() {
      this.$store.dispatch("unsetMessage");
    },
    editModeActive() {
      this.$store.dispatch("editModeActive");
    },
    handleCreateNewContext() {
      this.$store.dispatch("createNewContext");
    },
    cancelConfiguration() {
      // this.$store.dispatch("initializeApp");
      this.$store.dispatch("cancelConfiguration");
    },
    saveConfiguration() {
      this.$store.dispatch("saveConfiguration");
    },
    contextSelected(contextId) {
      this.$store.dispatch("handleSelectingContext", contextId);
    },
    acceptablesChanged(acceptablesId) {
      this.$store.commit("setSelectAcceptableId", acceptablesId);
    },
    acceptablesUpdated(acceptables) {
      this.$store.dispatch("acceptablesUpdated", acceptables);
    }
  }
};
</script>

<style lang="scss"></style>
