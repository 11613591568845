import gql from "graphql-tag";
import apollo from "./apolloClient";

const suffixEnv = process.env.VUE_APP_NODE_ENV === "production" ? "" : "_test";
const Api = {
  async createAcceptable({ acceptables, contextId }) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation InsertAcceptable {
          insert_acceptables${suffixEnv}(objects: {acceptable: ${JSON.stringify(
        acceptables,
        null,
        "\t"
      )}, context_id: "${contextId}"}) {
            returning {
              acceptable
              context_id
            }
          }
        }
      `
    });
    return response.data[`insert_acceptables${suffixEnv}`].returning[0];
  },
  async getKeywordById(keywordId) {
    const response = await apollo.query({
      query: gql`
        query getKeywordById {
          keywords${suffixEnv}(where: {id: {_eq: "${keywordId}"}}) {
            id
            name
            contexts(where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc_nulls_last, is_default: desc_nulls_last}) {
              id
              name
              created_at
              deleted_at
              is_default
              actual
              acceptables(order_by: {created_at: desc_nulls_last}) {
                id
                created_at
                acceptable
              }
            }
          }
        }
        
      `
    });
    // console.log("getKeywordById", response.data.keywords);
    return response.data[`keywords${suffixEnv}`];
  },
  async getContextsByKeywordId(keywordId) {
    const response = await apollo.query({
      query: gql`
        query getContextsByKeywordId {
          contexts${suffixEnv}(where: {deleted_at: {_is_null: true}, keyword_id: {_eq: "${keywordId}"}}, order_by: {created_at: desc_nulls_last}) {
            created_at
            id
            name
            keyword_id
            is_default
            actual
            acceptables(order_by: {created_at: desc_nulls_last}) {
              id,
              created_at,
              acceptable
            }
          }
        }
      `
    });
    // console.log("getContextsByKeywordId", response.data.contexts);
    return response.data[`contexts${suffixEnv}`];
  },
  async getContextByDefault(contexts) {
    for (let id in contexts) {
      const keywordId = contexts[id].keyword_id;
      const response = await apollo.query({
        query: gql`
          query getContextByDefault {
            contexts${suffixEnv}(where: {is_default: {_eq: "true"}, keyword_id: {_eq: "${keywordId}"}, deleted_at: {_is_null: true}}) {
              created_at
              keyword_id
              name
              id
              is_default
              actual
            }
          }      
        `
      });

      // console.log("getContextByDefault", response.data.contexts);
      return response.data[`contexts${suffixEnv}`];
    }
  },
  async getContextAcceptablesByContextId(context) {
    const contextId = context.id;
    const response = await apollo.query({
      query: gql`
        query getContextAcceptables {
          acceptables${suffixEnv}(order_by: {created_at: desc_nulls_last}, where: {context_id: {_eq: "${contextId}"}}) {
            context_id
            id
            created_at
            deleted_at
            acceptable
          }
        }      
      `
    });

    // console.log("getContextAcceptables", response.data.acceptables);
    return response.data[`acceptables${suffixEnv}`];
  },
  async saveContext(context) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation saveContext {
          update_contexts${suffixEnv}(
            where: { id: { _eq: "${context.id}" } }
            _set: { name: "${context.name}", is_default: ${
        context.is_default
      }, actual: ${JSON.stringify(context.actual)}, updated_at: "now()" }
          ) {
            returning {
              id
              name
              is_default
              updated_at
              actual
            }
          }
        }
      `
    });
    console.log("saveContext", response.data.update_contexts);
    return response.data[`update_contexts${suffixEnv}`].returning[0];
  },
  async deleteContexts(contextId) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation updateDefault {
          update_contexts${suffixEnv}(where: {id: {_eq: "${contextId}"}}, _set: {deleted_at: "now()"}) {
            affected_rows
            returning {
              name
              deleted_at
            }
          }
        }      
      `
    });

    console.log("deleteContexts", response.data);
    return response.data[`update_contexts${suffixEnv}`];
  },

  async updateContext(context) {
    const result = await apollo.mutate({
      variables: {
        id: context.id,
        actual: context.actual,
        is_default: context.is_default,
        name: context.name
      },
      mutation: gql`
        mutation updateContext(
          $id: String = ""
          $actual: json = ""
          $is_default: Boolean = false
          $name: String = ""
        ) {
          update_contexts${suffixEnv}_by_pk(
            pk_columns: { id: $id }
            _set: {
              actual: $actual
              is_default: $is_default
              name: $name
              updated_at: "NOW()"
            }
          ) {
            id
          }
        }
      `
    });
    console.log("updated context", result);
    return result.data[`update_contexts${suffixEnv}_by_pk`].id;
  },

  async createContext({
    actual,
    id,
    is_default,
    keyword_id,
    name,
    acceptables
  }) {
    console.log(acceptables);
    const result = await apollo.mutate({
      variables: {
        actual,
        id,
        is_default,
        keyword_id,
        name,
        acceptable: acceptables[0].acceptable
      },
      mutation: gql`
        mutation createContext(
          $acceptable: json = []
          $actual: json = ""
          $id: String = ""
          $is_default: Boolean = false
          $keyword_id: String = ""
          $name: String = ""
        ) {
          insert_contexts${suffixEnv}_one(
            object: {
              acceptables: { data: { acceptable: $acceptable } }
              actual: $actual
              id: $id
              is_default: $is_default
              keyword_id: $keyword_id
              name: $name
            }
          ) {
            id
            acceptables {
              acceptable
              context_id
              created_at
              id
            }
          }
        }
      `
    });
    return result.data[`insert_contexts${suffixEnv}_one`];
  },

  async updateAcceptables({ context_id, acceptable }) {
    console.log("context ID", context_id, "acceptable", acceptable);
    const result = await apollo.mutate({
      variables: { context_id, acceptable },
      mutation: gql`
        mutation updateAcceptablesById(
          $context_id: String = 10
          $acceptable: json = [[[""]]]
        ) {
          insert_acceptables${suffixEnv}_one(object: {context_id: $context_id, acceptable: $acceptable}) {
            id
            acceptable
            created_at
            context_id
          }
        }
      `
    });

    return result.data[`insert_acceptables${suffixEnv}_one`];
  }
};

export default Api;
