<template>
  <div class="loading-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="6.451612903225806" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.5s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="0s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.5s"
        ></animate>
      </circle>
      <circle cx="6.451612903225806" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.5s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.5s"
        ></animate>
      </circle>
      <circle cx="16.129032258064512" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.7s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.2s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.7s"
        ></animate>
      </circle>
      <circle cx="16.129032258064512" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.7s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.2s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.7s"
        ></animate>
      </circle>
      <circle cx="25.806451612903224" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.9s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.4s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.9s"
        ></animate>
      </circle>
      <circle cx="25.806451612903224" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.9s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.4s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.9s"
        ></animate>
      </circle>
      <circle cx="35.48387096774193" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.1s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.6s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.1s"
        ></animate>
      </circle>
      <circle cx="35.48387096774193" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.1s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.6s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.1s"
        ></animate>
      </circle>
      <circle cx="45.16129032258064" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.3s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-0.8s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.3s"
        ></animate>
      </circle>
      <circle cx="45.16129032258064" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.3s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.8s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.3s"
        ></animate>
      </circle>
      <circle cx="54.838709677419345" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.5s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.5s"
        ></animate>
      </circle>
      <circle cx="54.838709677419345" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.5s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-2s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.5s"
        ></animate>
      </circle>
      <circle cx="64.51612903225805" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.7s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.2s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.7s"
        ></animate>
      </circle>
      <circle cx="64.51612903225805" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.7s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.2s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.7s"
        ></animate>
      </circle>
      <circle cx="74.19354838709677" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.9s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.4s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.9s"
        ></animate>
      </circle>
      <circle cx="74.19354838709677" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.9s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.4s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.9s"
        ></animate>
      </circle>
      <circle cx="83.87096774193547" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.1s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.6s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.1s"
        ></animate>
      </circle>
      <circle cx="83.87096774193547" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-3.1s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.6s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.1s"
        ></animate>
      </circle>
      <circle cx="93.54838709677418" cy="50" r="3" fill="#5bdf1c">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.3s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-1.8s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#5bdf1c;#20a953;#5bdf1c"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.3s"
        ></animate>
      </circle>
      <circle cx="93.54838709677418" cy="50" r="3" fill="#55d2b6">
        <animate
          attributeName="r"
          times="0;0.5;1"
          values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
          dur="2s"
          repeatCount="indefinite"
          begin="-3.3s"
        ></animate>
        <animate
          attributeName="cy"
          keyTimes="0;0.5;1"
          values="32;68;32"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.8s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
        ></animate>
        <animate
          attributeName="fill"
          keyTimes="0;0.5;1"
          values="#55d2b6;#219a7f;#55d2b6"
          dur="2s"
          repeatCount="indefinite"
          begin="-2.3s"
        ></animate>
      </circle>
    </svg>
  </div>
</template>
<script>
export default {
  name: "LoadingIcon",
  components: {},
  props: {},
  computed: {},
  methods: {}
};
</script>
<style lang="scss">
.loading-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // height: 100vh;
  // width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>
