<template>
  <div class="container flex-grow-1 d-flex flex-column pt-4 pb-4 px-4">
    <div class="flex-grow-1 d-flex flex-column">
      <div
        class="
          d-flex
          flex-column flex-md-row
          align-items-md-baseline
          justify-content-between
          mb-4
          mb-md-3
        "
      >
        <keyword-title />
        <context-select
          @contextSelected="contextSelected"
          @createNewContext="contextCreated"
        />
      </div>
      <div
        class="
          flex-fill
          d-flex
          flex-column flex-md-row
          justify-content-md-between
        "
      >
        <context-editor
          class="me-md-5"
          @contextNameChanged="contextNameChanged"
          @contextIsValid="contextIsValid"
          @contextActualChanged="contextActualChanged"
          @contextDefaultChanged="contextDefaultChanged"
          @contextDeleted="contextDeleted"
        />
        <json-editor
          @acceptablesChanged="acceptablesChanged"
          @acceptablesUpdated="acceptablesUpdated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContextSelect from "../molecules/ContextSelect.vue";
import ContextEditor from "../molecules/ContextEditor.vue";
import KeywordTitle from "../organisms/KeywordTitle.vue";
import JsonEditor from "../molecules/JsonEditor.vue";
import axios from "axios";

export default {
  name: "keywordConfiguration",
  components: {
    ContextSelect,
    ContextEditor,
    KeywordTitle,
    JsonEditor
  },
  data() {
    return {
      contextCombined: {},
      selectedContext: {},
      selectedAcceptable: [],
      contextAcceptables: []
    };
  },
  mounted() {
    this.init();
    this.acceptablesValidatorUri =
      process.env.VUE_APP_ACCEPTABLES_VALIDATOR_URI;
  },
  computed: {
    keywordContexts() {
      const contexts = this.$store.getters.getKeywordContexts.filter(
        context => context?.name == this.selectedContext?.name
      );

      contexts.forEach(context => {
        context = {
          id: context.id,
          name: context.name,
          is_default: context.is_default,
          actual: context.actual
        };
        this.selectedContext = context;
      });
      return JSON.stringify(this.selectedContext, null, "\t");
      // return this.selectedContext;
    }
  },
  methods: {
    // contextPreview() {
    //   const contextPreviews = [];
    //   this.selectedContext = this.$store.getters.getKeywordContext;
    //   this.selectedAcceptable = this.$store.getters.getContextAcceptables[0]?.acceptable;
    //   contextPreviews.push(this.selectedContext, this.selectedAcceptable);
    //   contextPreviews.forEach(context => {
    //     context = {
    //       name: this.selectedContext.name,
    //       is_default: this.selectedContext.is_default,
    //       actual: this.selectedContext.actual,
    //       acceptables: this.selectedAcceptable
    //     };
    //     this.contextCombined = context;
    //   });
    //   // return _.sortBy(this.contextCombined, contextPreviews.filter(context => context.is_default))
    //   return JSON.stringify(this.contextCombined, null, "\t");
    // },
    init() {
      const acceptables = this.$store.getters.getContextAcceptables[0]
        ?.acceptable;
      if (acceptables) {
        // this.contextAcceptables = JSON.stringify(acceptables, null, "\t");
        this.contextAcceptables = acceptables;
      } else {
        this.contextAcceptables = [[[]]];
      }
    },
    async validateAcceptables() {
      try {
        const acceptables = JSON.parse(this.contextAcceptables);
        await axios.post(this.acceptablesValidatorUri, {
          acceptables
        });
        return acceptables;
      } catch (error) {
        const { code, message } = error.response.data;
        if (code == 400) {
          throw new Error(message);
        }
      }
    },
    contextSelected(contextId) {
      this.$emit("contextSelected", contextId);
    },
    acceptablesContextSelected(selectedAcceptable) {
      this.selectedAcceptable = selectedAcceptable;
      this.$store.dispatch("setContextAcceptables", selectedAcceptable);
    },
    savingAcceptables(acceptables) {
      this.$store.dispatch("setContextAcceptables", acceptables);
    },
    contextDefaultSelected(contexts) {
      this.$store.dispatch("setContextDefaultSelection", contexts);
    },
    contextDeleted(context) {
      this.$store.dispatch("contextDeleted", context);
    },
    contextActualChanged(context) {
      this.$store.dispatch("contextActualUpdated", context);
    },
    contextDefaultChanged(context) {
      this.$store.dispatch("contextDefaultUpdated", context);
    },
    contextNameChanged(context) {
      this.$store.dispatch("contextNameUpdated", context);
    },
    contextIsValid(validation) {
      console.log(validation)
      this.$store.dispatch("contextIsValid", validation);
    },
    contextCreated() {
      this.$store.dispatch("createNewContext");
    },
    acceptablesChanged(acceptablesId) {
      this.$emit("acceptablesChanged", acceptablesId);
    },
    acceptablesUpdated(acceptables) {
      this.$emit("acceptablesUpdated", acceptables);
    },    
    contextEditModeActive() {
      this.$store.dispatch("contextEditModeActive");
    },
    exitContextEditMode() {
      this.$store.dispatch("contextEditModeActive");
    },
    acceptablesEditModeActive() {
      this.$store.dispatch("acceptablesEditModeActive");
    },
    exitAcceptablesEditMode() {
      this.$store.dispatch("acceptablesEditModeActive");
    }
  },
  watch: {
    "$store.getters.getContextAcceptables": {
      deep: true,
      handler: function() {
        this.init();
      }
    }
  }
};
</script>
<style lang="scss">
.section-title {
  // border-bottom: 1px solid var(--table-border-color, #eee);
  background: #f8f9fa;
  display: flex;
  align-items: baseline;
  // height: 3rem;
  padding-bottom: 0.15em;
  position: relative;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}
</style>
