<template>
  <div class="app__title d-flex">
    <div class="app__title-logo d-flex align-items-center px-3">
      <img
        src="../../assets/media/images/healthscholars-tree-icon-dark-green.svg"
        alt=""
        class="hs-logo flex-grow-1"
      />
    </div>
    <p
      class="m-0 my-auto ps-3 fs-7"
      title="Keyword Configuration Assistance Tool"
    >
      KCAT
      <!-- <span class="d-flex align-items-center"><small class="keyword-label pt-1 pe-2 fs-6">keyword: </small><span class="keyword">{{ title }}</span></span> -->
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: String
  }
};
</script>
<style lang="scss" scoped>
abbr[title] {
  text-decoration: none;
}

.app__title-logo {
  background: #eee;
  box-shadow: inset -1px 0 0 #ddd;
  min-height: 50px;
}

.hs-logo {
  height: 30px;
}

.keyword {
  font-family: monospace;
}
</style>
