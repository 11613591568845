<template>
  <div class="section-title">
    <div class="d-flex text-center py-3">
      <h2 class="my-auto">{{ keywordId }}</h2>
      <!-- <small class="form-text ps-2">[{{ intentName }}]</small> -->
    </div>
  </div>
</template>
<script>

export default {
  name: "KeywordTitle",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    keywordId() {
      return this.$store.getters.getKeyword[0]?.id
    },
  },
  methods: {
  }
};
</script>
<style lang="scss"></style>
