<template>
  <div class="d-flex algn-items-center justify-content-between">
    <div class="keyword-select form-floating mt-2 mt-md-0">
      <select
        class="form-select"
        aria-label="form-select"
        v-model="selectedContext"
        data-test-context-select
      >
        <option
          v-for="context in keywordContexts"
          :key="context.id"
          :value="context.id"
          :selected="isSelected"
        >
          {{ context.name }}
        </option>
      </select>
      <button
        type="button"
        class="btn link-secondary me-2 py-0 px-1"
        @click="handleCreateNewContext()"
        data-test-context-create
      >
        <span class="material-icons"> post_add </span>
      </button>
      <label for="" class="form-label">contexts</label>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContextSelect",
  components: {},
  props: {},
  data() {
    return {
      isSelected: false
    };
  },
  mounted() {
    // this.contextLoaded(this.contextIsDefault)
  },
  computed: {
    keywordContexts() {
      return this.$store.getters.getKeywordContexts;
      // return _.sortBy(this.$store.getters.getKeywordContexts, this.$store.getters.getKeywordContexts.filter(context => context.is_default))
    },
    selectedContext: {
      set(contextId) {
        this.$emit("contextSelected", contextId);
      },
      get() {
        return this.$store.state.selectedContextId;
      }
    }
  },
  methods: {
    contextIsDefault() {
      const defaultContext = this.$store.getters.getKeywordContexts.find(
        context => context.is_default
      );
      // const latestContext = new Date(Math.max(...this.$store.getters.getKeywordContexts?.map(context => new Date(context.created_at))));
      const latestContext = this.$store.getters.getKeywordContexts[0];

      if (defaultContext != undefined) {
        this.isSelected = true;
        return defaultContext;
      } else {
        return latestContext;
      }
    },
    handleCreateNewContext() {
      this.$emit("createNewContext");
    }
  }
};
</script>
<style lang="scss">
.keyword-select {
  width: 100%;

  .form-select {
    background-position: right 2.75rem center;
  }

  .btn {
    position: absolute;
    top: 15px;
    right: 0;
  }

  @media screen and (min-width: 768px) {
    margin-left: auto;
    width: 250px;
  }
}

.keyword-select-actions {
  --mdc-icon-size: 18px;
  // --mdc-icon-button-size: 40px;
}
</style>
