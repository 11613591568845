import Vue from "vue";
import Vuex from "vuex";
import Api from "../api";
import createUuid from "./helpers/createUuid";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appTitle: "KCAT",
    appVersion: "",
    alert: {
      message: "",
      title: "",
      level: null,
      timeToDisplay: 5000,
      active: false
    },
    contextEditMode: false,
    dataIsLoaded: false,
    keyword: {},
    keywordContext: {},
    keywordContexts: [],
    contextAcceptables: [],
    selectedContextId: "",
    selectAcceptableId: null
  },
  mutations: {
    addKeywordContexts(state, context) {
      const contexts = cloneDeep(state.keywordContexts);
      contexts.push(context);
      Vue.set(state, "keywordContexts", contexts);
    },
    addNewAcceptable(state, newAcceptable) {
      const acceptables = cloneDeep(state.contextAcceptables);
      acceptables.unshift(newAcceptable);
      Vue.set(state, "contextAcceptables", acceptables);
    },
    addNewKeywordContext(state, keywordContext) {
      const clonedKeywordContexts = cloneDeep(state.keywordContexts);
      clonedKeywordContexts.push(keywordContext);
      Vue.set(state, "keywordContexts", clonedKeywordContexts);
    },
    removeKeywordContext(state, contextId) {
      const clonedKeywordContexts = cloneDeep(state.keywordContexts);
      const index = clonedKeywordContexts.findIndex(
        context => context.id == contextId
      );
      if (index >= 0) {
        clonedKeywordContexts.splice(index, 1);
      }
      Vue.set(state, "keywordContexts", clonedKeywordContexts);
    },
    setAlertMessage(state, { title, message, level }) {
      const { alert } = state;
      alert.title = title;
      alert.message = message;
      alert.level = level;
      alert.active = true;
      Vue.set(state, "alert", alert);
    },
    unsetAlert(state) {
      const { alert } = state;
      alert.active = false;
      Vue.set(state, "alert", alert);
    },
    setSelectAcceptableId(state, acceptablesId) {
      Vue.set(state, "selectAcceptableId", acceptablesId);
    },
    setKeyword(state, keyword) {
      Vue.set(state, "keyword", keyword);
    },
    setKeywordContext(state, context) {
      Vue.set(state, "keywordContext", context);
    },
    setKeywordContexts(state, contexts) {
      Vue.set(state, "keywordContexts", contexts);
    },
    setKeywordContextName(state, contexts) {
      Vue.set(state, "keywordContexts", contexts);
    },
    setKeywordContextAcceptables(state, acceptables) {
      Vue.set(state, "contextAcceptables", acceptables);
    },
    setContextEditMode(state, mode = false) {
      Vue.set(state, "contextEditMode", mode);
    },
    setContextIsValid(state, validation) {
      Vue.set(state, "contextIsValid", validation);
    },
    updateKeywordContext(state, updatedContext) {
      const context = state.keywordContexts.find(
        keywordContext => keywordContext.id == updatedContext.id
      );
      if (context) {
        context.name = updatedContext.name;
        context.actual = updatedContext.actual;
        context.is_default = updatedContext.is_default;
        Vue.set(state, "keywordContexts", cloneDeep(state.keywordContexts));
      }
    },
    setSelectedContextId(state, contextId) {
      Vue.set(state, "selectedContextId", contextId);
    },
    setAcceptables(state, acceptables) {
      const contexts = cloneDeep(state.keywordContexts);
      const context = contexts.find(
        context => context.id == state.selectedContextId
      );
      try {
        context.acceptables[0].acceptable = acceptables;
        Vue.set(state, "keywordContexts", contexts);
      } catch (error) {
        throw new Error(error);
      }
    },
    markContextAsClean(state, contextId) {
      try {
        const clonedContexts = cloneDeep(state.keywordContexts);
        const context = clonedContexts.find(context => context.id == contextId);
        context.state = "clean";
        Vue.set(state, "keywordContexts", clonedContexts);
      } catch (error) {
        console.error("error marking context as clean", error);
        throw error;
      }
    },
    markNewAcceptableAsClean(state, acceptables) {
      // set the id
      // mark as clean
      const clonedContexts = cloneDeep(state.keywordContexts);
      const context = clonedContexts.find(
        clonedContext => clonedContext.id == acceptables.context_id
      );
      if (!context) return;
      const stateAcceptables = context.acceptables.find(
        acceptables => acceptables.state === "new"
      );
      if (!stateAcceptables) return;
      stateAcceptables.state = "clean";
      stateAcceptables.id = acceptables.id;
      stateAcceptables.created_at = acceptables.created_at;
      Vue.set(state, "keywordContexts", clonedContexts);
    },
    updateAcceptable(state, { contextId, acceptables }) {
      const clonedContexts = cloneDeep(state.keywordContexts);
      const clonedContext = clonedContexts.find(
        context => context.id == contextId
      );
      if (!clonedContext) throw new Error("Could not find context");
      clonedContext.acceptables = acceptables;
      Vue.set(state, "keywordContexts", clonedContexts);
    },
    recoverAcceptable(state, { acceptableId, contextId }) {
      const keywordContexts = cloneDeep(state.keywordContexts);

      const selectedContext = keywordContexts.find(
        context => context.id == contextId
      );

      if (!selectedContext) throw new Error("Could not find selected context");

      const selectedAcceptableIndex = selectedContext.acceptables.findIndex(
        acceptable => acceptable.id == acceptableId
      );

      if (selectedAcceptableIndex < 0) return;

      if (selectedAcceptableIndex > 0) {
        const selectedAcceptable = selectedContext.acceptables.find(
          acceptable => acceptable.id == acceptableId
        );

        if (!selectedAcceptable) return;

        const newAcceptable = cloneDeep(selectedAcceptable);

        newAcceptable.state = "new";

        newAcceptable.id = v4();

        selectedContext.acceptables.unshift(newAcceptable);

        Vue.set(state, "keywordContexts", keywordContexts);
      }
    }
  },
  getters: {
    getKeyword(state) {
      return state.keyword;
    },
    getKeywordContext(state) {
      return state.keywordContext;
    },
    getKeywordContexts(state) {
      return state.keywordContexts;
    },
    getContextAcceptables(state) {
      return state.contextAcceptables;
    },
    getSelectedAcceptables(state, getters) {
      if (!state.selectAcceptableId) return null;
      return getters.getSelectedContext.acceptables.filter(
        acceptables => acceptables.id == state.selectAcceptableId
      )[0];
    },
    keywordId(state, getters) {
      const keywordId = getters.getKeyword[0]?.id;
      if (!keywordId) throw new Error("could not find keyword id");
      return keywordId;
    },
    getSelectedContext(state) {
      const selectedContext = state.keywordContexts.find(
        context => context.id == state.selectedContextId
      );
      return selectedContext;
    },
    contextNameIsValid(state, getters) {
      return getters.getSelectedContext?.name.length > 0;
    },
    contextActualIsValid(state, getters) {
      return getters.getSelectedContext?.actual[0].length > 0;
    }
  },
  actions: {
    async initializeApp({ commit, state }, keywordId) {
      const keyword = await Api.getKeywordById(keywordId);
      let contexts = await Api.getContextsByKeywordId(keywordId);
      contexts = contexts.map(context => {
        context.state = "clean";
        if (context.acceptables && !context.acceptables.length) {
          context.acceptables = [
            {
              id: v4(),
              acceptable: [[[""]]],
              state: "new"
            }
          ];
        } else {
          context.acceptables[0].state = "clean";
        }
        return context;
      });
      if (!contexts.length) {
        const id = createUuid();
        const newContext = {
          actual: [],
          id: id,
          is_default: true,
          keyword_id: keywordId,
          name: "DEFAULT",
          acceptables: [
            {
              id: null,
              acceptable: [[[""]]]
            }
          ],
          state: "new"
        };
        contexts.push(newContext);
      }
      if (keyword == undefined || keyword.length == 0) {
        const alert = {
          message: "Context Does Not Exist",
          title: "Oh No!",
          level: "danger",
          timeToDisplay: 5000,
          active: false
        };
        commit("setAlertMessage", alert);
      } else {
        state.dataIsLoaded = true;
        commit("setKeyword", keyword);
        commit("setKeywordContexts", contexts);
      }
      const selectedContextId = contexts.reduce((id, context) => {
        if (context.is_default) return context.id;
        return id;
      }, contexts[0].id);
      commit("setSelectedContextId", selectedContextId);
      const selectedContext = contexts.filter(
        context => context.id == selectedContextId
      )[0];
      const selectAcceptableId = selectedContext.acceptables[0].id;
      commit("setSelectAcceptableId", selectAcceptableId);
    },
    async createNewContext({ getters, commit }) {
      const newContext = {
        actual: [],
        id: v4(),
        is_default: false,
        keyword_id: getters.keywordId,
        name: "",
        state: "new",
        acceptables: [
          {
            id: v4(),
            acceptable: [[[""]]],
            state: "new"
          }
        ]
      };
      commit("setContextEditMode", true);
      commit("addKeywordContexts", newContext);
      commit("setKeywordContext", newContext);
      commit("setSelectedContextId", newContext.id);
    },
    async saveConfiguration({ state, commit }) {
      commit("recoverAcceptable", {
        acceptableId: state.selectAcceptableId,
        contextId: state.selectedContextId
      });
      state.keywordContexts.forEach(async keywordContext => {
        switch (keywordContext.state) {
          case "dirty": {
            const updatedContextId = await Api.updateContext(keywordContext);
            commit("markContextAsClean", updatedContextId);
            break;
          }
          case "new": {
            const createdContext = await Api.createContext(keywordContext);
            commit("markContextAsClean", createdContext.id);
            commit("updateAcceptable", {
              contextId: createdContext.id,
              acceptables: createdContext.acceptables
            });
            break;
          }
          default:
            break;
        }

        switch (keywordContext.acceptables[0].state) {
          case "new": {
            const acceptables = await Api.updateAcceptables({
              context_id: keywordContext.id,
              acceptable: keywordContext.acceptables[0].acceptable
            });
            commit("markNewAcceptableAsClean", acceptables);
            commit("setSelectAcceptableId", acceptables.id);
            break;
          }
          default:
            break;
        }
      });
    },
    async setContext({ commit }, context) {
      const acceptables = await Api.getContextAcceptablesByContextId(context);
      commit("setKeywordContext", context);
      commit("setKeywordContextAcceptables", acceptables);
      commit("setContextEditMode", false);
    },
    async setContextDefaultSelection({ commit }, contexts) {
      commit("setKeywordContexts", contexts);
    },
    async setContextAcceptables({ commit }, { acceptables, contextId }) {
      const createdAcceptable = await Api.createAcceptable({
        acceptables,
        contextId
      });
      console.log("created acceptables", createdAcceptable);
      commit("addNewAcceptable", createdAcceptable);
      commit("setAcceptablesEditMode", false);
    },
    async contextCreated({ commit, state }, context) {
      const keywordContexts = cloneDeep(state.keywordContexts);
      const newContext = await Api.createContext(context);
      keywordContexts.push(newContext);
      commit("setKeywordContexts", keywordContexts);
    },
    async contextDeleted({ commit, state }, contextId) {
      if (state.keywordContexts.length == 1) return;

      await Api.deleteContexts(contextId);
      commit("setContextEditMode", false);
      const firstNotDeletedContext = state.keywordContexts.find(
        context => context.id != contextId
      );
      if (firstNotDeletedContext) {
        commit("setKeywordContext", cloneDeep(firstNotDeletedContext));
      }
      commit("removeKeywordContext", contextId);
    },
    async acceptablesUpdated({ commit, state }, { acceptables }) {
      const clonedContexts = cloneDeep(state.keywordContexts);
      const context = clonedContexts.find(
        context => context.id == state.selectedContextId
      );
      if (!context) throw new Error("could not find contexts");
      const clonedAcceptables = context.acceptables.find(
        acceptable => acceptable.id == state.selectAcceptableId
      );
      if (!clonedAcceptables) throw new Error("could not find acceptables");
      if (clonedAcceptables.state == "clean") {
        const newAcceptable = cloneDeep(clonedAcceptables);
        newAcceptable.id = v4();
        newAcceptable.state = "new";
        newAcceptable.acceptable = acceptables;
        newAcceptable.created_at = new Date();
        context.acceptables.unshift(newAcceptable);
        commit("setSelectAcceptableId", newAcceptable.id);
      } else {
        clonedAcceptables.acceptable = acceptables;
      }
      commit("setKeywordContexts", clonedContexts);
    },
    async contextNameUpdated({ commit, state }, context) {
      const keywordContexts = cloneDeep(state.keywordContexts);
      const updatedContext = keywordContexts.find(
        keywordContext => keywordContext.id == context.id
      );
      updatedContext.name = context.name;
      if (updatedContext.state == "clean") {
        updatedContext.state = "dirty";
      }
      commit("setKeywordContexts", keywordContexts);
    },
    async contextActualUpdated({ commit, state }, context) {
      const keywordContexts = cloneDeep(state.keywordContexts);
      const updatedContext = keywordContexts.find(
        keywordContext => keywordContext.id == context.id
      );
      updatedContext.actual = context.actual
        .split(",")
        .map(actual => actual.trim());
      if (updatedContext.state == "clean") {
        updatedContext.state = "dirty";
      }
      commit("setKeywordContexts", keywordContexts);
    },
    async contextDefaultUpdated({ commit, state }, context) {
      const keywordContexts = cloneDeep(state.keywordContexts);
      const updatedContext = keywordContexts.find(
        keywordContext => keywordContext.id == context.id
      );
      updatedContext.is_default = context.is_default;
      if (updatedContext.state == "clean") {
        updatedContext.state = "dirty";
      }
      commit("setKeywordContexts", keywordContexts);
    },
    setMessage({ commit, state, dispatch }, alert) {
      commit("setAlertMessage", alert);
      setTimeout(() => dispatch("unsetMessage"), state.alert.timeToDisplay);
    },
    unsetMessage({ commit }) {
      commit("unsetAlert");
    },
    contextEditModeActive({ commit, state }) {
      commit("setContextEditMode", !state.contextEditMode);
    },
    async cancelConfiguration({ getters, commit }) {
      const keywordId = getters.keywordId;
      const contexts = await Api.getContextsByKeywordId(keywordId);
      commit("setContextEditMode", false);
      commit("setKeywordContexts", contexts);
    },
    handleSelectingContext({ commit, state }, contextId) {
      const context = state.keywordContexts.find(
        context => context.id == contextId
      );
      if (!context) throw new Error("could not find context");
      const acceptableId = context.acceptables[0].id;
      commit("setSelectedContextId", contextId);
      commit("setSelectAcceptableId", acceptableId);
    }
  }
});
