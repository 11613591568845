<template>
  <header class="page__header d-flex py-0 sticky">
    <div
      class="
        bg-white
        container-fluid
        d-flex
        flex-wrap
        justify-content-between
        px-0
      "
    >
      <app-title :title="$store.state.keyword.id" />

      <div class="d-flex">
        <save-configuration
          :mode="editMode"
          :disabled="isDisabled"
          @saveConfiguration="saveConfiguration"
          @cancelConfiguration="cancelConfiguration"
        />        
      </div>
    </div>
  </header>
</template>

<script>
import AppTitle from "./AppTitle.vue";
import SaveConfiguration from "./SaveConfiguration.vue";

export default {
  name: "AppHeader",
  components: {
    AppTitle,
    SaveConfiguration,
  },
  data() {
    return {};
  },
  computed: {
    configurationVersions() {
      const versions = [
        {
          id: "d9b7a994-910a-48d0-a85c-3affabc7fb5b",
          version_number: "0.1.0",
        },
        {
          id: "b88d3879-7527-42fe-91cf-02df26b4cb65",
          version_number: "0.1.1",
        },
        {
          id: "6cb47010-5d8d-4096-85da-82c1d617a430",
          version_number: "0.1.2",
        },
        {
          id: "f4d45e6c-b330-40a3-ba57-c001d2648645",
          version_number: "0.1.3",
        },
        {
          id: "4edaef79-9392-4d7f-b2ab-a6355604a89d",
          version_number: "0.1.4",
        },
      ];
      return versions;
    },
    editMode() {
      return this.$store.getters.getSelectedContext?.state == "new" || this.$store.getters.getSelectedContext?.state == "dirty" ? true : false
    },
    isDisabled() {
      return !this.$store.getters.contextNameIsValid ||
             !this.$store.getters.contextActualIsValid ||
             !this.$store.getters.getSelectedAcceptables?.state == "new"
    }
  },
  methods: {
    handleEditMode() {
      this.$emit("editModeActive");
    },
    cancelConfiguration() {
      this.$emit("cancelConfiguration");
    },
    saveConfiguration() {
      this.$emit("saveConfiguration");
    },
  },
};
</script>
<style lang="scss"></style>
